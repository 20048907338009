// 用户接口管理
import {Request} from '@/http/request'

// 获取带参数二维码
export function wechatTicket(parameter: any) {
    // parameter ={"type":"cloud","key":"用户id"};
    return Request.axiosInstance.post('/sso/wechat/ticket', parameter)
}

// 获取微信用户列表
export function wxUserList(parameter: any) {
    return Request.axiosInstance.post('/web/wx-user/list',parameter)
}

export default {wechatTicket, wxUserList};