<template>
  <a-modal :visible="dialogVisible"
           title="绑定微信用户" width="30%" :style="{minHeight:'60%'}"
           @cancel="dialogClose">
    <div class="m3-flex-col m3-align-items-center">
      <setQrcode :text="wechatUrl" :src="wechatUrl?'':''" :width="200" :height="200" :size="90"></setQrcode>
      <span class="m3-margin-top-s">打开微信-扫一扫，关注公众号后即可绑定</span>
    </div>
    <template #footer>
      <div class="m3-flex m3-justify-content-end">
        <!--        <a-button class="m3-margin-right-m" @click="dialogClose">取消</a-button>-->
        <a-button type="primary"
                  :loading="dialogLoading"
                  @click="dialogConfirm">关闭
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import AModal from "ant-design-vue/lib/modal/Modal";
import {ref, toRefs, watch} from "vue";
import {safePromise} from "@/utils/common/safePromise";
import wechatApi from "@/api/open/wechat/wechatApi";
import SetQrcode from "@/components/setQrcode.vue";
import EmptyUtil from "@/utils/common/EmptyUtil";

export default {
  name: "BindWechatDialog",
  emits: ['update:dialogVisible', 'confirm','refresh'],
  components: {SetQrcode, AModal},
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    uid: {
      default: '',
    }
  },
  setup(props, context) {
    let {dialogVisible, uid} = toRefs(props);
    let dialogLoading = ref(false);

    let wechatUrl = ref('');
    // 获取带参数二维码
    let getWechatTicket = async () => {
      if (!uid.value) {
        wechatUrl.value = '';
        return;
      }
      let res = await safePromise(wechatApi.wechatTicket({"type": "cloud", "key": uid.value, showError: false}));
      // console.log('getWechatTicket', res?.data?.url)
      wechatUrl.value = res?.data?.url
    };
    //
    // watch(uid, (newV, preV) => {
    //   if (newV && newV != preV) {
    //     getWechatTicket();
    //   }
    // })
    watch(dialogVisible, (newV, preV) => {
      if (newV) {
        if (EmptyUtil.isEmpty(wechatUrl.value)) {
          getWechatTicket();
        }
      } else {
        wechatUrl.value = '';
        if (preV){
          //由于不知道是否扫码过，在弹窗关闭时刷新列表数据，这样才可以得到openid。
          context.emit('refresh',true);
        }
      }
    })
    let dialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let dialogConfirm = () => {
      context.emit('update:dialogVisible', false);
      context.emit('confirm', wechatUrl.value);
    }
    return {
      wechatUrl,
      dialogClose, dialogConfirm, dialogLoading,
    }
  }
}
</script>

<style scoped>

</style>